/** @format */

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal } from 'react-bootstrap';

export default function ModalWrapper(props) {
  const {
    children,
    heading,
    description,
    openModal,
    open,
    className = ''
  } = props;

  const closeModal = () => {
    openModal(false);
  };

  return (
    <>
      <Modal
        size={'lg'}
        centered
        show={open}
        onHide={closeModal}
        className={`modal ${className}`}>
        <Modal.Header closeButton />

        <div className='modal-inner-container'>
          <Container>
            <h3>{heading}</h3>
            <Row>
              <Col md={12}>
                {description && (
                  <p className='text2r modal-description'>{description}</p>
                )}
                {children}
              </Col>
            </Row>
          </Container>
        </div>
      </Modal>
    </>
  );
}
