/** @format */

import { BID_SETTING } from './types';

export const isInShowBiddingMode = (biddingSetting) => {
  if (biddingSetting === BID_SETTING.SHOW_BIDDING) {
    return true;
  }
  return false;
};
