/** @format */

import React, { useEffect } from 'react';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
import Container from 'react-bootstrap/Container';
import BrokerProfileContent from 'views/Common/BrokerProfile/BrokerProfileContent';
import getUrlParams from 'utils/getUrlParams';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchBrokerObjects,
  fetchBrokerObjectsBySlug,
  fetchBrokerProfile,
  fetchBrokerProfileBySlug
} from 'stateContainer/actions';
import { Spinner } from 'react-bootstrap';
import CustomButton from 'components/CustomButton';
import { Link, useParams } from 'react-router-dom';
import { LeadsGeneratorModal, LeadSources } from 'components/LeadsGenerator';
// import ValuationOfMyHome from 'components/Modals/ValuationOfMyHome';

export default function BrokerProfile(props) {
  const dispatch = useDispatch();
  const {
    brokerProfile: {
      data: { user, searchable, isAdminView },
      isFetching,
      isFetchingMore
    },
    brokerObjects: { data, isFetching: isObjectsFetching, page, totalPages }
  } = useSelector((state) => state);

  const { tprop } = props;

  const { profileId, profileRef } = getUrlParams(['profileId', 'profileRef']);

  const { profileSlug } = useParams();

  const target = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const [copyProfileUrlSuccess, setCopyProfileUrlSuccess] = React.useState('');

  const [displayContactSeller, setDisplayContactSeller] = React.useState(false);

  const copyToClipBoard = async (copiedText) => {
    await navigator.clipboard.writeText(copiedText);
    setCopyProfileUrlSuccess(tprop.header.copyLink.text);
  };

  const onClickCopyProfile = () => {
    const trimmedUrl = window.location.href.split('&')[0];
    copyToClipBoard(trimmedUrl);
    setShow(!show);
    setTimeout(() => {
      setShow(false);
    }, 1500);
  };

  const isProfileVisible = searchable || isAdminView;

  useEffect(() => {
    if (profileId) {
      dispatch(fetchBrokerProfile({ brokerId: profileId, profileRef }));
      isProfileVisible &&
        dispatch(fetchBrokerObjects({ brokerId: profileId, profileRef }));
    } else if (profileSlug) {
      dispatch(
        fetchBrokerProfileBySlug({ brokerSlug: profileSlug, profileRef })
      );
      isProfileVisible &&
        dispatch(
          fetchBrokerObjectsBySlug({ brokerSlug: profileSlug, profileRef })
        );
    }
  }, [searchable, isAdminView]);

  const ProfileContent = () => {
    return (
      <Container fluid className='content-wrapper'>
        {user !== undefined && (
          <>
            <BrokerProfileContent
              {...props}
              properties={data}
              muncipality={user.muncipality}
              brokerEmail={user.fmEmail}
              brokerPhone={user.phonenumber}
              copyToClipBoard={copyToClipBoard}
              copyProfileUrlSuccess={copyProfileUrlSuccess}
              onClickCopyProfile={onClickCopyProfile}
              target={target}
              show={show}
              status={user.status}
              activeObjectCount={user.activeObjectCount}
              completedSalesCount={user.completedSalesCount}
              showCopySuccess={show}
              firstName={user.firstName}
              lastName={user.lastName}
              profilePic={user.image}
              description={user.description}
              services={user.services}
              usps={user.usp}
              totalPages={totalPages}
              page={page}
              profileId={profileId}
              profileSlug={profileSlug}
              isFetchingMore={isFetchingMore}
              profileRef={profileRef}
              region={user?.region}
            />
          </>
        )}
      </Container>
    );
  };

  const ProfileNotPublic = () => {
    return (
      <Container
        fluid
        className='content-wrapper broker-profile-not-public-container'>
        <div className='broker-profile-not-public-actions'>
          <h2>{tprop.profile_not_public.heading.text}</h2>
          <Link to={tprop.profile_not_public.button.url}>
            <CustomButton text={tprop.profile_not_public.button.text} />
          </Link>
        </div>
        <div className='image-broker-profile-not-public' />
      </Container>
    );
  };
  const isLoading = isFetching || isObjectsFetching;
  return (
    <>
      <div className='main-container broker-profile-view'>
        <Header
          flow={props.flow}
          showContactSellerButton={true}
          setDisplayContactSeller={setDisplayContactSeller}
          {...props}
        />

        {isLoading && (
          <Container fluid className='broker-profile-loading-container'>
            <Spinner
              animation='border'
              role='status'
              style={{ color: 'var(--color-primary-pink)' }}
            />
          </Container>
        )}

        {isProfileVisible && !isLoading && <ProfileContent />}

        {!isProfileVisible && !isLoading && <ProfileNotPublic />}

        <Footer {...props} />
      </div>

      <div className='main-container broker-profile-view-mobile'>
        <Header
          flow={props.flow}
          showContactSellerButton={true}
          setDisplayContactSeller={setDisplayContactSeller}
          {...props}
        />

        {isProfileVisible && !isLoading && <ProfileContent />}

        {!isProfileVisible && !isLoading && <ProfileNotPublic />}

        <Footer {...props} />
      </div>

      <LeadsGeneratorModal
        open={displayContactSeller}
        handler={setDisplayContactSeller}
        metadata={{
          source: LeadSources.FM_BROKER_PROFILE,
          brokerId: profileId || undefined
        }}
        {...props}
      />

      {/* <ValuationOfMyHome
        customerId={null}
        openValuationOfMyHome={setDisplayContactSeller}
        open={displayContactSeller}
      /> */}
    </>
  );
}
