/** @format */

import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';

import ImageCard from 'views/Common/BrokerProfile/ImageCard';
import ContactIcons from 'views/Common/BrokerProfile/ContactIcons';
import BrokerServiceBox from './BrokerServiceBox';

import FetchMore from 'views/Common/BrokerProfile/FetchMore';
import { useSelector } from 'react-redux';
import { EqualHeight } from 'react-equal-height';

export default function BrokerProfileContent(props) {
  const {
    target,
    brokerEmail,
    brokerPhone,
    showCopySuccess,
    onClickCopyProfile,
    copyProfileUrlSuccess,
    properties,
    firstName,
    lastName,
    profilePic,
    description,
    services,
    usps,
    tprop,
    profileRef,
    region
  } = props;

  function generatePropertyUrl(propertyId, vitecObjectId = null) {
    const checkProfileRef = () => {
      return profileRef && profileRef !== 'undefined';
    };

    let viewObjectUrl = props.t('lang_route:common_object_listing');
    if (vitecObjectId) {
      return (viewObjectUrl = `${viewObjectUrl}/${vitecObjectId}${
        checkProfileRef() ? `?profileRef=${profileRef}` : ''
      }`);
    }
    return (viewObjectUrl = `${viewObjectUrl}?objectId=${propertyId}${
      checkProfileRef() ? `&profileRef=${profileRef}` : ''
    }`);
  }

  const { data } = useSelector((state) => state.brokerObjects);

  const [key, setKey] = React.useState('allProperties');

  const [loading, setLoading] = React.useState(true);

  function after(count, f) {
    let noOfCalls = 0;
    return function (...rest) {
      noOfCalls = noOfCalls + 1;
      if (count === noOfCalls) {
        f(...rest);
      }
    };
  }

  const onComplete = after(properties.length, () => {
    setLoading(false);
  });

  /* ----------- Filter commented out ---------- */
  // const Filter = () => {
  //   return (
  //     <Formik initialValues={initialValues} onSubmit={onSubmit}>
  //       {() => (
  //         <Form className='form-group filter-objects-form'>
  //           <Row>
  //             <Col>
  //               <label>{tprop.filter.location.text}</label>
  //               <Field
  //                 as='select'
  //                 className='form-control text4r input-location'
  //                 type=''
  //                 name='location'
  //                 {...props}>
  //                 <option value='0'>Select here</option>
  //                 <option value='red'>Red</option>
  //                 <option value='green'>Green</option>
  //                 <option value='blue'>Blue</option>
  //               </Field>
  //             </Col>
  //             <Col>
  //               <label>{tprop.filter.propertyType.text}</label>
  //               <Field
  //                 as='select'
  //                 className='form-control input-property-type'
  //                 type=''
  //                 name='propertyType'
  //                 {...props}>
  //                 <option value='0'>Select here</option>
  //                 <option value='red'>Red</option>
  //                 <option value='green'>Green</option>
  //                 <option value='blue'>Blue</option>
  //               </Field>
  //             </Col>
  //             <Col>
  //               <label>{tprop.filter.price.text}</label>
  //               <Field
  //                 className='form-control input-min-price'
  //                 type=''
  //                 name='minPrice'
  //                 placeholder='Min'
  //                 {...props}
  //               />
  //             </Col>
  //             <div className='price-seperator'>-</div>
  //             <Col className='add-top-margin'>
  //               <Field
  //                 className='form-control input-max-price'
  //                 type=''
  //                 name='maxPrice'
  //                 placeholder='Max'
  //                 {...props}
  //               />
  //             </Col>
  //             <CustomButton
  //               className='add-top-margin button-filter'
  //               isBgPink
  //               text={tprop.filter.buttons.text}
  //             />
  //           </Row>
  //         </Form>
  //       )}
  //     </Formik>
  //   );
  // };

  const {
    descriptionTitle,
    myServicesTitle,
    uspTitle,
    services: servicesText,
    verified
  } = props.t('lang_onboarding:brokerProfile');

  const { banner } = props.t('lang_common:brokerProfile');

  const { dimensionsUnit } = props.t('lang_common:broker_profile');

  const hasZeroPropertiesListed = properties.length === 0;

  const hasLessThanSixPropertiesListed =
    properties.length > 0 && properties.length < 6;

  const showBanner = hasZeroPropertiesListed;

  const Banner = ({ text }) => {
    return (
      <div className='broker-properties-banner'>
        <div className='banner-banner-left icon-exclamation'></div>
        <div className='banner-banner-right'>{text}</div>
      </div>
    );
  };

  const ListOfObjectsContainer = (isMobile = false) => {
    return (
      <>
        <div className='list-of-objects-container'>
          {/* <Filter /> */}
          <p className='broker-profile-subheading'>{tprop.subheading.text}</p>

          {hasZeroPropertiesListed && !hasLessThanSixPropertiesListed && (
            <Banner text={banner.hasZeroPropertiesListed.text} />
          )}

          {!showBanner && properties !== undefined && (
            <Row xs={1} md={3} className='g-4 listing-container'>
              <EqualHeight updateOnChange={!loading}>
                {properties.map(
                  (
                    {
                      id,
                      type,
                      location,
                      noOfBathrooms,
                      noOfBedrooms,
                      price,
                      startingPrice,
                      finalPrice,
                      livingSpace,
                      status,
                      imageUrl,
                      vitecObjectId
                    },
                    key
                  ) => (
                    <Col key={key}>
                      <Link
                        to={generatePropertyUrl(id, vitecObjectId)}
                        key={key}>
                        <ImageCard
                          type={type}
                          location={location}
                          price={price}
                          startingPrice={startingPrice}
                          finalPrice={finalPrice}
                          bedrooms={noOfBedrooms}
                          bathrooms={noOfBathrooms}
                          dimensions={livingSpace}
                          currency={props.t('lang_onboarding:currency')}
                          status={status}
                          imageUrl={imageUrl}
                          dimensionsUnit={dimensionsUnit}
                          onComplete={onComplete}
                          isMobile={isMobile}
                          translation={
                            props.t('lang_common:object_listing').propertyType
                          }
                        />
                      </Link>
                    </Col>
                  )
                )}
              </EqualHeight>
            </Row>
          )}

          {hasLessThanSixPropertiesListed && !hasZeroPropertiesListed && (
            <Banner text={banner.hasLessThanSixPropertiesListed.text} />
          )}

          {data.length > 0 && <FetchMore {...props} />}
        </div>
      </>
    );
  };

  function getServices(index) {
    switch (index) {
      case 0:
        if (services[index].houseService) return true;
        break;

      case 1:
        if (services[index].apartmentService) return true;
        break;

      case 2:
        if (services[index].cottageService) return true;
        break;

      case 3:
        if (services[index].plotService) return true;
        break;

      case 4:
        if (services[index].farmService) return true;
        break;

      default:
        return false;
    }
  }

  const AddressContainer = () => {
    return (
      <div className='profile-address-container'>
        <div className='profile-address-icon icon-location'></div>
        <div className='profile-address-address text2r'>{region}</div>
      </div>
    );
  };

  const MyServices = (props = { mobileHidden: false }) => {
    return (
      <div
        className={`profile-my-services-container ${
          props.mobileHidden && 'mobile-hidden'
        }`}>
        <div className='text3m'>{myServicesTitle}</div>
        <div className='profile-my-services-row'>
          {servicesText.map(
            (service, index) =>
              getServices(index) && (
                <BrokerServiceBox index={index} service={service} key={index} />
              )
          )}
        </div>
      </div>
    );
  };

  const ProfileUSP = ({ isMobile }) => {
    const addClassName = isMobile ? '-mobile' : '';
    return (
      <Col md={3} className={`profile-usp-container${addClassName}`}>
        <div className='profile-usp-inner-container'>
          <div className='text3m'>{uspTitle}</div>
          <ul className='profile-usplist'>
            {usps.map(({ name }, index) => (
              <li className='text2r' key={index}>
                {name}
              </li>
            ))}
          </ul>
        </div>
      </Col>
    );
  };

  const ProfileDescription = () => {
    return (
      <Row className='profile-description-container'>
        <Col md={9} className='description-box'>
          <div className='text3m'>{descriptionTitle}</div>
          <p className='text2r'>{description}</p>
        </Col>
        <MyServices mobileHidden={true} />
      </Row>
    );
  };

  const overviewText = props.t(
    `lang_common:broker_profile.mobiletabs.overview.text`
  );
  const allPropertiesText = props.t(
    `lang_common:broker_profile.mobiletabs.allproperties.text`
  );

  return (
    <>
      <>
        <div className='profile-container'>
          <div className='profile-container-left'>
            <img className='image-profile-foreground' src={profilePic} />

            <div className='contact-icon-container-desktop'>
              <ContactIcons
                brokerPhone={brokerPhone}
                brokerEmail={brokerEmail}
                target={target}
                onClickCopyProfile={onClickCopyProfile}
                showCopySuccess={showCopySuccess}
                copyProfileUrlSuccess={copyProfileUrlSuccess}
              />
            </div>
          </div>

          <img className='image-profile-foreground-mobile' src={profilePic} />
          <div className='profile-container-right'>
            <div className='profile-heading-container'>
              <h2 className='profile-heading-heading'>
                {firstName + ' ' + lastName}
              </h2>

              <AddressContainer />

              <p className='profile-verified-container'>
                <img src='images/image-tick-green.svg' />
                {verified}
              </p>
            </div>
            <AddressContainer />
            <div className='contact-icon-container-mobile'>
              <ContactIcons
                brokerPhone={brokerPhone}
                brokerEmail={brokerEmail}
                target={target}
                onClickCopyProfile={onClickCopyProfile}
                showCopySuccess={showCopySuccess}
                copyProfileUrlSuccess={copyProfileUrlSuccess}
              />
            </div>
            <div className='profile-description-and-usp-box'>
              <ProfileDescription />
              <ProfileUSP isMobile={false} />
            </div>
          </div>
        </div>
      </>
      <div className='mobile-tabs'>
        <Tabs activeKey={key} onSelect={(k) => setKey(k)} className='mb-3'>
          <Tab eventKey='overview' title={overviewText}>
            <ProfileDescription />
            <ProfileUSP isMobile={true} />
            <MyServices />
          </Tab>
          <Tab eventKey='allProperties' title={allPropertiesText}>
            <ListOfObjectsContainer arraylength={3} isMobile={true} />
          </Tab>
        </Tabs>
      </div>
      {/** tabs that were temporarily removed from design are below */}
      {/* <Tabs activeKey={key} onSelect={(k) => setKey(k)} className='mb-3'>
        <Tab eventKey='activeObjects' title='Active Objects'>
          <ListOfObjectsContainer arraylength={8} />
        </Tab>
        <Tab eventKey='completedSales' title='Completed Sales'>
          <ListOfObjectsContainer arraylength={3} />
        </Tab>
      </Tabs> */}
      <ListOfObjectsContainer isMobile={false} />
    </>
  );
}
