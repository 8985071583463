/** @format */

import React from 'react';
import ModalWrapper from '..';
import moment from 'moment';
import 'moment/locale/sv';
import { useTranslation } from 'react-i18next';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BID_SETTING } from 'utils/types';

export default function BiddingsModal(props) {
  const { bids, open, openBidding, bidSetting } = props;

  const closeModal = () => {
    openBidding(false);
  };

  const { t } = useTranslation();
  const text = t('lang_common:object_listing:modals:bidding');

  moment.locale('sv', {
    months:
      'Januari_Februari_Mars_April_Maj_Juni_Juli_Augusti_September_Oktober_November_December'.split(
        '_'
      ),
    monthsParseExact: true,
    weekdays: 'Söndag_Måndag_Tisdag_Onsdag_Torsdag_Fredag_Lördag'.split('_'),

    weekdaysParseExact: true
  });

  const BiddingHiddenNotice = () => (
    <tr>
      <td colSpan={3}>
        <div className='bidding-modal bidding-hidden-text'>
          <FontAwesomeIcon icon={faCircleInfo} className='bullet-style' />
          <span>{text.contact_broker.text}</span>
        </div>
      </td>
    </tr>
  );

  const NoBidsAvailableNotice = () => (
    <tr>
      <td colSpan={3}>
        <div className='bidding-modal bidding-hidden-text'>
          <FontAwesomeIcon icon={faCircleInfo} className='bullet-style' />
          <span>{text.no_biddings.text}</span>
        </div>
      </td>
    </tr>
  );

  const BidsList = () => {
    if (bids.length === 0) {
      return <NoBidsAvailableNotice />;
    }
    return bids.map((bid) => {
      const startDate = moment(bid.dateAndTime)
        .locale('sv')
        .format('dddd, DD/MM/YY [kl] HH:mm');
      const endDate = moment(bid.endTime).format('HH:mm');
      return (
        <tr key={bid.id}>
          <td>{bid.alias}</td>
          <td>{bid.amount}</td>
          <td className='text-capitalize'>{`${startDate} - ${endDate}`}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <ModalWrapper
        open={open}
        openModal={closeModal}
        heading={text.heading.label}>
        <table className='table table-responsive table-bordered'>
          <thead>
            <tr>
              <th>{text.alias.label}</th>
              <th>{text.amount.label}</th>
              <th>{text.dateTime.label}</th>
            </tr>
          </thead>
          <tbody>
            {bidSetting === BID_SETTING.DONT_SHOW_BIDDING && (
              <BiddingHiddenNotice />
            )}
            {bidSetting !== BID_SETTING.DONT_SHOW_BIDDING && <BidsList />}
          </tbody>
        </table>
      </ModalWrapper>
    </>
  );
}
