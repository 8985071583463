/** @format */
import React from 'react';

export const BiddingBanner = ({
  wrapperClassName = null,
  bannerClassName = 'btn-plain',
  animation = false,
  labelText
}) => {
  const Banner = () => (
    <div
      className={`btn object-bidding-indicator in-progress ${bannerClassName} ${
        animation && 'animate'
      }`}>
      {/* should get from locaize */}
      {labelText}
    </div>
  );

  if (wrapperClassName) {
    return (
      <div className={wrapperClassName}>
        <Banner />
      </div>
    );
  }
  return <Banner />;
};
