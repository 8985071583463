/** @format */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import utils, { ENV_MODES } from 'utils';

export const FindBrokerSearchBar = () => {
  if (utils.getCurrentEnv() !== ENV_MODES.PROD) {
    return (
      <div className='find-broker-search-bar'>
        <FontAwesomeIcon icon={faSearch} className='find-broker-search-icon' />
        <input
          id='findBroker'
          name='findBroker'
          type='text'
          placeholder='Hitta mäklare'
          className=''
          // onChange={formik.handleChange}
          // value={formik.values.name}
        />
      </div>
    );
  } else {
    return <></>;
  }
};
