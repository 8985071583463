/** @format */

import getUrlParams from 'utils/getUrlParams';

export const ENV_MODES = {
  DEV: 'DEV',
  QA: 'QA',
  PROD: 'PROD'
};

const getCurrentEnv = () => {
  const qaRegex = /\/qa\.friamaklare\.se\//;
  const prodRegex = /\/friamaklare\.se\//;

  const currentUrl = window.location.href;

  if (qaRegex.test(currentUrl)) {
    console.log('QA');
    return ENV_MODES.QA;
  } else if (prodRegex.test(currentUrl)) {
    console.log('PROD');
    return ENV_MODES.PROD;
  } else {
    console.log('DEV');
    return ENV_MODES.DEV;
  }
};

const utils = {
  getUrlParams,
  getCurrentEnv
};
export default utils;
