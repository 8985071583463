/** @format */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
import Container from 'react-bootstrap/Container';
import getUrlParams from 'utils/getUrlParams';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBrokerObject } from 'stateContainer/actions';
import { Spinner } from 'react-bootstrap';
import ObjectListingContents from 'views/Common/ObjectListing/ObjectListingContents';
import BookAViewing from 'components/Modals/BookAViewing';
// import ValuationOfMyHome from 'components/Modals/ValuationOfMyHome';
import BiddingsModal from 'components/Modals/BiddingsModal';
import { LeadsGeneratorModal, LeadSources } from 'components/LeadsGenerator';

export default function ObjectListing(props) {
  const { objectId, profileRef } = getUrlParams(['objectId', 'profileRef']);
  const { vitecObjectId } = useParams();
  const {
    brokerObject: {
      data: { object, broker, estate },
      isFetching
    }
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (vitecObjectId) {
      dispatch(fetchBrokerObject({ vitecObjectId, profileRef }));
    } else {
      dispatch(fetchBrokerObject({ objectId, profileRef }));
    }
  }, [objectId, vitecObjectId]);

  const Loader = () => {
    return (
      <Container fluid className='broker-profile-loading-container'>
        <Spinner
          animation='border'
          role='status'
          style={{ color: 'var(--color-primary-pink)' }}
        />
      </Container>
    );
  };

  const [openBookAViewingModal, setOpenBookAViewingModal] = useState(false);
  const [openValuationOfMyHomeModal, setOpenValuationOfMyHomeModal] =
    useState(false);

  const [openBiddingModal, setOpenBiddingModal] = useState(false);

  const objectDataExists =
    object && object !== undefined && Object.keys(object).length > 0;

  const viewingDataExists =
    estate && estate !== undefined && Object.keys(estate).length > 0;

  return (
    <>
      <div className='main-container imagelist-view'>
        <Header
          flow={props.flow}
          showContactSellerButton={true}
          setDisplayContactSeller={setOpenValuationOfMyHomeModal}
          {...props}
        />
        {isFetching ? (
          <Loader />
        ) : (
          <Container fluid className='content-wrapper'>
            {objectDataExists && (
              <ObjectListingContents
                {...props}
                title={object.title}
                description={object.description}
                coordinates={object.coordinate}
                dimensions={object.dimensions}
                brokerDetails={broker}
                openBookAViewing={setOpenBookAViewingModal}
                openValuationOfMyHome={setOpenValuationOfMyHomeModal}
                openBidding={setOpenBiddingModal}
                estate={estate}
                object={object}
              />
            )}
          </Container>
        )}
        <Footer {...props} />
      </div>

      <div className='main-container imagelist-view-mobile'>
        <Header
          flow={props.flow}
          showContactSellerButton={true}
          setDisplayContactSeller={setOpenValuationOfMyHomeModal}
          {...props}
        />
        {isFetching ? (
          <Loader />
        ) : (
          <>
            <Container fluid className='content-wrapper'>
              {objectDataExists && (
                <Container fluid className='content-wrapper'>
                  <ObjectListingContents
                    {...props}
                    title={object.title}
                    description={object.description}
                    coordinates={object.coordinate}
                    dimensions={object.dimensions}
                    brokerDetails={broker}
                    openBookAViewing={setOpenBookAViewingModal}
                    openValuationOfMyHome={setOpenValuationOfMyHomeModal}
                    openBidding={setOpenBiddingModal}
                    estate={estate}
                    object={object}
                  />
                </Container>
              )}
            </Container>
            {viewingDataExists && objectDataExists && (
              <BookAViewing
                open={openBookAViewingModal}
                openBookAViewing={setOpenBookAViewingModal}
                viewings={estate.viewings}
                objectId={object.id}
                customerId={estate.customerId}
                brokerId={estate.vitecBrokerId}
              />
            )}
            {objectDataExists && (
              <LeadsGeneratorModal
                open={openValuationOfMyHomeModal}
                handler={setOpenValuationOfMyHomeModal}
                metadata={{
                  source: LeadSources.FM_OBJECT_PROFILE,
                  objectId: object.id || undefined
                }}
                {...props}
              />
            )}
            {/* {objectDataExists && (
              <ValuationOfMyHome
                customerId={estate.customerId}
                openValuationOfMyHome={setOpenValuationOfMyHomeModal}
                open={openValuationOfMyHomeModal}
              />
            )} */}
            {viewingDataExists && (
              <BiddingsModal
                bids={estate.bids}
                open={openBiddingModal}
                openBidding={setOpenBiddingModal}
                bidSetting={estate.settings?.bidSetting}
              />
            )}
          </>
        )}

        <Footer {...props} />
      </div>
    </>
  );
}
